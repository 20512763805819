<template>
  <div class="forget_password_form_wrapper">
    <div class="form-box">
      <p class="title">{{ $t('common.button.forgotPw') }}</p>
      <el-form label-position="top" :model="emailForm" @submit.native.prevent :rules="rule" ref="emailForm">
        <p class="tip">{{ $t('forgetPwReq.tip') }}</p>
        <div class="submit-field">
          <el-form-item prop="email">
            <div class="login_label">{{ $t('common.field.email') }}</div>
            <el-input v-model.trim="emailForm.email" size="default" :placeholder="$t('common.field.email')"
              autocomplete="off" name="email" :maxlength="64">
            </el-input>
          </el-form-item>
          <el-button class="submit_btn" @click="submitForm('emailForm')" data-testid="sendResetPw">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </el-form>
      <p class="error" v-if="errorFlag">{{ errMsg }}</p>
    </div>
    <SDialog :visible="!formFlag" mode="simple" :showFooterCancel="false" :title="$t('common.field.tips')"
      custom-class="result_success" @cancel="goHome" @confirm="goHome">
      <StatusMessage :title="$t('promotion.successful')" class="forget_password_success">
        <template slot="content">
          <p class="success_text" v-html="$t('forgetPwReq.succ')"></p>
          <p class="email" v-if="emailForm.email">{{ emailForm.email }}</p>
        </template>
      </StatusMessage>
    </SDialog>
  </div>
</template>

<script>
import { apiReq_reset_profile_password } from '@/resource';
import router from '@/router';
import { validateCommonEmail } from '@/util/validation';

export default {
  name: 'forgetPasswordReq',
  components: {},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('loginClient.formValidation.emailReq')));
      } else {
        if (!validateCommonEmail(value)) {
          callback(new Error(this.$t('common.formValidation.emailFormatWrong')));
        } else {
          callback()
        }
      }
    }
    return {
      formFlag: true,
      emailForm: {
        email: '',
      },
      rule: {
        email: [
          { required: true, validator: validateEmail, trigger: 'blur' }
        ],
      },
      errMsg: '',
      errorFlag: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.getApiReq_reset_profile_password();
        } else return false;
      });
    },
    getApiReq_reset_profile_password() {
      this.errMsg = '';
      this.errorFlag = false;

      apiReq_reset_profile_password({
        email: this.emailForm.email,
        baseUrl: location.protocol + '//' + location.hostname,
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data.result == true) {
            this.formFlag = false;
          }
        } else if (resp.data.code === 421) {
          this.errMsg = resp.data.errmsg;
          this.errorFlag = true;
        }
      });
    },
    goHome() {
      router.push('/home');
    },
  },
};
</script>

<style lang="scss">
.forget_password_form_wrapper {
  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .tip {
    font-size: 12px;
    color: $text-secondary;
    margin: 22px 0 82px 0;
    text-align: center;
  }

  .login_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);
  }

  .verification-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .submit_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

  .error {
    font-size: 14px;
    color: $danger;
    margin-top: 24px;
    text-align: center;
  }

  .el-button:active {
    border: none;
  }

  .el-input__inner {
    border-radius: 8px;
    padding: 8px 16px;
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .el-input__inner:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }
}

.forget_password_success {
  text-align: center;

  .success_text {
    font-size: 14px;
    line-height: 22px;
    color: $text-primary;
    margin-top: 16px;
  }

  .email {
    font-size: 14px;
    line-height: 22px;
    color: $text-link;
    margin-top: 16px;
  }
}
</style>
